import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function ListTextItem(props) {
  const { onClick, icon, text, key } = props;
  return (
    <ListItem button key={key} onClick={onClick}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={text} />
    </ListItem>
  );
}
ListTextItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.element,
  text: PropTypes.string,
  key: PropTypes.string,
};

ListTextItem.defaultProps = {
  onClick: () => {
    console.log('click');
  },
  icon: null,
  text: 'click me',
  key: 'key',
};
export default ListTextItem;
