import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { ActionBtn, AlertDialog, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import MemberForm from './MemberForm';
import { membersMng } from '../api/membersMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditMemberDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onEdit, member } = props;

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ isValid: true });
  const [prevRoleId, setPrevRoleId] = React.useState();
  const [updateData, setUpdateData] = React.useState(false);
  const [
    openAlertOnRoleChangeDialog,
    setOpenAlertOnRoleChangeDialog,
  ] = React.useState(false);

  const getRoleId = () => {
    let roleId = '';
    if (
      member.roles &&
      member.roles.some((r) => ['admin', 'manager', 'root'].includes(r))
    ) {
      roleId = 'admin';
    } else if (
      member.settings &&
      member.settings.sites &&
      member.settings.sites.role
    ) {
      roleId = member.settings.sites.role._id;
    }
    return roleId;
  };
  React.useEffect(() => {
    const { profile, emails } = member;
    const values = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: emails[0].address,
    };
    const roleId = getRoleId();
    values.roleId = roleId;
    setPrevRoleId(roleId);
    setFormData({ values });
  }, [member]);

  const _onClose = () => {
    onClose();
  };
  const editMember = async () => {
    const updateData = { ...formData.values };
    updateData.memberId = member._id;
    updateData.prevRoleId = prevRoleId;
    setUpdateData(updateData);
    if (updateData.roleId === prevRoleId) {
      saveChanges(updateData);
    } else if (updateData.roleId === 'admin' && prevRoleId !== 'admin') {
      setOpenAlertOnRoleChangeDialog('upgrade');
    } else if (updateData.roleId !== 'admin' && prevRoleId === 'admin') {
      setOpenAlertOnRoleChangeDialog('downgrade');
    } else {
      setOpenAlertOnRoleChangeDialog('change');
    }
  };

  const saveChanges = async (_updateData = updateData) => {
    setLoading(true);
    console.log(_updateData);
    try {
      await membersMng.updateMember(_updateData);
      onEdit();
      onClose();
      toast.success('Member updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onEditFormChange = (newFormData) => {
    setFormData({ ...Object.assign(formData, newFormData) });
  };
  if (!member || !open) {
    return null;
  }

  const getDescription = () => {
    if (openAlertOnRoleChangeDialog === 'upgrade') {
      return (
        <span>
          Upgrading member role to admin will give them all privileges.
          <br />
          Changing role will force refresh user session.
          <br /> Are you sure you want to continue?
        </span>
      );
    } else if (openAlertOnRoleChangeDialog === 'downgrade') {
      return (
        <span>
          Are you sure you want to downgrade member from admin role? <br />
          They will lose all privileges and force refresh their session if any.
        </span>
      );
    } else {
      return (
        <span>
          Are you sure you want to change member role?
          <br />
          It will force refresh their active session if any
        </span>
      );
    }
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Edit Member
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <AlertDialog
          open={openAlertOnRoleChangeDialog}
          loading={loading}
          description={getDescription()}
          onClose={() => {
            setOpenAlertOnRoleChangeDialog(false);
          }}
          confirmBtn={<span style={{ color: 'green' }}>Confirm</span>}
          onConfirm={() => {
            saveChanges();
            setOpenAlertOnRoleChangeDialog(false);
          }}
        />
        <MemberForm
          validateOnInit={true}
          isEditForm={true}
          onChange={onEditFormChange}
          data={formData}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save Member</span>}
            onClick={editMember}
            onEnter={editMember}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditMemberDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editMember: PropTypes.func,
  member: PropTypes.object,
  onEdit: PropTypes.func,
};

EditMemberDialog.defaultProps = {
  open: false,
  onClose: () => {},
  member: {},
};

export default EditMemberDialog;
