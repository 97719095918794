import React from 'react';
import PropTypes from 'prop-types';

import { Form, ImageUploader } from '@meronex/components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';

function SaleForm(props) {
  const { onUpdate = () => {}, formData = {}, validateOnInit } = props;

  const previewContainerRef = React.useRef();

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  const values = formData.values || {};

  return (
    <div>
      <Form
        validateOnInit={validateOnInit}
        blurDelay={500}
        customValues={{
          checked: formData?.values?.checked,
        }}
        onUpdate={onUpdate}>
        <TextField
          name={'name'}
          label="Name"
          autoFocus={true}
          defaultValue={(values || {}).name}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
        <TextField
          placeholder={'select'}
          variant="outlined"
          defaultValue={(values || {}).select}
          label="Select"
          name={'select'}
          required
          fullWidth
          select>
          <MenuItem value={1}>one</MenuItem>
        </TextField>
        <div
          style={{
            marginTop: '12px',
            marginBottom: '5px',
            color: 'gray',
          }}>
          <label>
            <span
              style={{
                marginLeft: '5px',
              }}>
              Check Value
            </span>
          </label>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checked}
                onChange={() => {
                  const _fromData = { ...formData };
                  _fromData.values.checked = !_fromData.values.checked;
                  onUpdate(_fromData);
                }}
                name="checkValue"
                color="primary"
              />
            }
            label="Check Value"
          />
        </div>
        <div
          style={{
            marginTop: '10px',
          }}>
          <ImageUploader
            defaultPreviewUrl={
              values && values.image ? values.image.imgUrl : ''
            }
            previewContainer={previewContainerRef}
            onUploadStart={() => {
              setUploading(true);
            }}
            onUploadSuccess={(imageData) => {
              console.log(imageData);
              setImgUrl(imageData.imgUrl);
              setThumbUrl(imageData.thumbUrl);
              setUploading(false);
            }}
          />
        </div>
      </Form>
      <div ref={previewContainerRef} style={{ width: '100%' }} />
    </div>
  );
}
SaleForm.propTypes = {
  formData: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onUpdate: PropTypes.func,
};

SaleForm.defaultProps = {
  formData: {},
  validateOnInit: false,
  onUpdate: (values) => console.log(values),
};
export default SaleForm;
