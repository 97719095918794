import React from 'react';
import PropTypes from 'prop-types';

import IAdd from '@meronex/icons/md/MdAddCircleOutline';
import IWarn from '@meronex/icons/ti/TiWarning';

const CLICKABLE_COLOR = 'rgb(59, 151, 186)';
function NoData(props) {
  const { message, style, iconSize, onClick, icon, type } = props;

  let _icon = icon;
  const mergedStyle = Object.assign(
    {
      cursor: typeof onClick === 'function' ? 'pointer' : 'auto',
      padding: '50px',
      textAlign: 'center',
      fontSize: '20px',
    },
    style
  );
  if (!icon) {
    if (type === 'warn') {
      _icon = (
        <IWarn
          size={iconSize}
          style={{
            textAlign: 'center',
            color: 'lightgray',
          }}
        />
      );
    } else {
      _icon = (
        <IAdd
          size={iconSize}
          style={{
            textAlign: 'center',
            color:
              typeof onClick === 'function' ? CLICKABLE_COLOR : 'lightgray',
          }}
        />
      );
    }
  }
  return (
    <div style={mergedStyle} onClick={onClick} id={'noData'}>
      {_icon}
      <div
        style={{
          textAlign: 'center',
          marginTop: '12px',
          color:
            typeof onClick === 'function' && type !== 'warn'
              ? CLICKABLE_COLOR
              : 'lightgray',
          fontWeight: '200',
        }}>
        {message}
      </div>
    </div>
  );
}
NoData.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  type: PropTypes.oneOf(['add', 'warn']),
};

NoData.defaultProps = {
  message: 'No Data Available',
  iconSize: 60,
  onClick: () => {},
  type: 'add',
};

export default NoData;
