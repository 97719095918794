import React from 'react';
import PropTypes from 'prop-types';

import {
  NoData,
  AlertDialog,
  toast,
  Loading,
  EndlessScroll,
} from '@meronex/components';
import { App } from '@meronex/app';

import List from '@material-ui/core/List';
import { useWindowSize } from '@react-hook/window-size';

import SaleItem from './SaleItem';
import { salesMng } from '../api/salesMng';

function SalesList(props) {
  const { sales, onClick, onEdit, onDelete } = props;
  const [width, height] = useWindowSize();

  const [deleting, setDeleting] = React.useState();
  const endlessScrollListRef = React.useRef(null);

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState();

  React.useEffect(() => {
    refreshList(true, true);
  }, [sales]);

  const _onDelete = (sale) => {
    setOpenDeleteAlert(true);
    setSelectedItem(sale);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await salesMng.deleteSale(selectedItem);
    toast.success('Sale successfully deleted');
    setDeleting(false);
    onDelete(selectedItem);
  };

  const getListHeight = () => {
    let isMobile = App.utils.isMobile();
    // increase the offset to see more/increase height
    let offset = isMobile ? 0.1 : 0.25;
    return height - height * offset;
  };

  const getItemHeight = (item) => {
    return 120;
  };

  const refreshList = (scrollToTop, clearItems) => {
    console.log('[SaleList]: refresh list..');
    window.skip = null;
    if (endlessScrollListRef.current) {
      endlessScrollListRef.current.refreshList(scrollToTop, clearItems);
    }
  };

  const fetchSaleItems = async ({ limit, skip }) => {
    console.log(`fetchSaleItems: limit: ${limit}, skip:  ${skip}`);
    return sales.slice(skip, skip + limit);
  };

  const renderItem = (sale, index) => {
    if (!sale) {
      return null;
    }
    return (
      <SaleItem
        sale={sale}
        onEdit={onEdit}
        onDelete={_onDelete}
        onClick={onClick}
      />
    );
  };

  return (
    <>
      <EndlessScroll
        limit={15} // make sure limit is large enough to fill the screen height so on delete won't get stuck
        ref={endlessScrollListRef}
        height={getListHeight()}
        remoteRowCount={9999999}
        selectedItemIndex={selectedItemIndex}
        listStyle={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
        renderItem={(data, isScrolling, index) => {
          return renderItem(data, index);
        }}
        itemHeight={(item) => {
          return getItemHeight(item);
        }}
        lastItemHeight={(item) => {
          return getItemHeight(item) + 135;
        }}
        noRowRenderer={() => {
          return (
            <div>
              <NoData
                onClick={() => {}}
                style={{
                  padding: '50px',
                }}
                message={'Add sale item'}
              />
            </div>
          );
        }}
        getListItem={fetchSaleItems}
        getListItems={fetchSaleItems}
      />
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
SalesList.propTypes = {
  sales: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

SalesList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default SalesList;
