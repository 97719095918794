import React from 'react';

/**
 * The form component has a two parts, the form class and the React component
 * The component has a reference to the class
 *
 * Basically the form takes a list a components, the form object will hook to the following attributes
 * - defaultValue
 * - value
 * - onChange
 * - validate
 *
 * For custom components, can pass the customValues prop, and the form will merge the custom values with the form values, if
 * not passed, the form will ignore the default value of the custom field on init since it will grab those values
 * from the components
 *

 * Uses import { Form } from '@meronex/components';
 * - The form handle validation on blur and on init
 * - The form basically render children and assume the following props attached to the child
 *  - name
 *  - validate: function to validate
 *  - defaultValue: string, first time value
 *  - value: current value
 * - Validation
 *  - pass validate function, if valid return true, otherwise returns string, the form will attach the string to the helper text

 * - The form has this main method:
 *  - onChange(fromData): basically returns the form data as they change, with the list of the errors and decision if the form is valid
 *  - And exposes
 *    - validate
 *    - reset
 * - The form currently supports MUI TextField, and Select by default
 *
 * - Custom fields
 *  For custom fields, you field needs to update the formData values on change, and it will also need to
 *  let the form know that there is a custom value  by passing the custom value prop, otherwise the form will
 *  ignore the default value on init
 *
 *  Can also wrap the custom field with FormField component
 *
 */
export class FormClass {
  constructor(config, onUpdateFun, blurDelay, customValues) {
    this.onUpdateFunc = onUpdateFun;
    this.blurDelay = blurDelay;
    this.init(config);
    this.isFormValid();
    this.customValues = customValues;
  }

  init(config) {
    this.config = config;
    this.data = {
      config,
      values: {},
      state: {},
      errors: {},
    };
    this.enteries = Object.entries(config);

    const values = { ...this.customValues };
    const state = {};
    const errors = {};

    for (const [key, fieldConfig] of this.enteries) {
      if (typeof fieldConfig.defaultValue !== 'undefined') {
        values[key] = fieldConfig.defaultValue;
      } else {
        values[key] = undefined;
      }
      state[key] = undefined;
      errors[key] = undefined;
    }

    this.data.state = state;
    this.data.values = values;
    this.data.errors = errors;
    this.data.state.formValid = undefined;
  }
  isFormValid() {
    let isValid;
    let validCount = 0;
    Object.values(this.data.errors).forEach((v) => {
      if (typeof v === 'string') {
        isValid = false;
      } else if (v === true) {
        validCount += 1;
      }
    });
    if (validCount === this.config.validatorsCount) {
      isValid = true;
    }
    return isValid;
  }
  isFieldValid(f) {
    return typeof this.data.errors[f] === 'string';
  }

  onBlur(f) {
    this.blurTimeout = setTimeout(() => {
      this.onChange(f, this.data.values[f]);
    }, this.blurDelay);
  }
  onCustomValuesChange = (customValues = {}) => {
    this.customValues = customValues;
    Object.assign(this.data.values, customValues);
  };
  onChange(f, v) {
    if (f) {
      this.data.values[f] = v;
      this.validate(f);
    }

    if (this.onUpdateFunc) {
      this.onUpdateFunc({ ...this.data });
    }
  }

  getValue(f) {
    return this.data.values[f];
  }
  getError(f) {
    return this.data.errors[f];
  }
  getData() {
    return { ...this.data };
  }

  validate(f) {
    const fieldConfig = this.config[f];
    let error = false,
      state = {};

    if (fieldConfig && typeof fieldConfig.validate === 'function') {
      error = fieldConfig.validate(this.data.values[f]);
      state = Boolean(error);
    }
    this.data.errors[f] = error;
    this.data.state[f] = state;
    const _isFormValid = this.isFormValid();
    this.data.state.formValid = _isFormValid;
    this.data.isValid = _isFormValid;
    return this.getData();
  }
  reset(fireOnChange = true) {
    this.init(this.config);
    window.clearTimeout(this.blurTimeout);
    if (fireOnChange) {
      this.onChange();
    }
  }

  static init(config, cb) {
    const newForm = new Form(config, cb);
    return React.useRef(newForm).current;
  }
}

export const FormField = (props) => {
  const { form, name } = props;
  return React.createElement(
    React.Fragment,
    null,
    React.cloneElement(props.children, {
      value: form.getData().values[name],
      onChange: (e) => {
        form.onChange(name, e.target.value);
      },
      onBlur: () => form.onBlur(name),
      helperText: form.getError(name),
      error: form.getError(name),
    })
  );
};

export const Form = React.forwardRef((props, ref) => {
  const {
    onUpdate,
    blurDelay = 0,
    validateOnInit = false,
    customValues = {},
  } = props;
  const [initialized, setInitialized] = React.useState(false);

  const form = React.useRef(
    new FormClass(
      {
        customValues,
      },
      onUpdate,
      blurDelay,
      validateOnInit
    )
  ).current;

  if (typeof onUpdate === 'function') {
    form.onUpdateFunc = onUpdate;
  }

  React.useEffect(() => {
    const valuesArray = Object.entries(form.data.values);

    if (validateOnInit) {
      valuesArray.forEach((v) => {
        form.validate(v[0]);
      });
      form.onChange();
    }

    setInitialized(true);

    return () => form.reset(false);
  }, []);

  React.useEffect(() => {
    form.onCustomValuesChange(customValues);
  }, [customValues]);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      form.reset();
    },
    validate: () => {
      form.validate();
    },
  }));
  let children = React.Children.toArray(props.children);

  return React.createElement(
    React.Fragment,
    null,
    children.map((c, i) => {
      if (!React.isValidElement(c)) {
        return;
      }
      let name, validate, defaultValue, value;
      if (c.props) {
        name = c.props.name;
        validate = c.props.validate;
        defaultValue = c.props.defaultValue;
        value = c.props.value;
      }

      if (!form.config.validatorsCount) {
        form.config.validatorsCount = 0;
      }
      if (name) {
        if (!form.config[name]) {
          form.config[name] = {};
        }

        if (!initialized) {
          if (typeof validate === 'function') {
            form.config[name].validate = c.props.validate;
            form.config.validatorsCount += 1;
          }
          if (typeof defaultValue !== 'undefined') {
            form.data.values[name] = defaultValue;
            form.config[name].defaultValue = defaultValue;
          } else if (typeof value !== 'undefined') {
            form.data.values[name] = value;
            form.config[name].defaultValue = undefined;
          } else {
            form.data.values[name] = undefined;
            form.config[name].defaultValue = undefined;
          }
        }
      }
      let childProps =
        typeof name !== 'undefined'
          ? {
              key: i,
              validate: undefined,
              defaultValue,
              value,
              onChange: (e) => {
                form.onChange(name, e.target.value);
              },
              onBlur: () => form.onBlur(name),
              helperText: React.createElement(
                'span',
                null,
                form.getError(name)
              ),
              error: form.isFieldValid(name),
            }
          : { key: i };
      return React.cloneElement(c, childProps);
    })
  );
});

Form.validators = {
  isValidEmail: (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  },
};
export default Form;
