import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import SaleForm from './SaleForm';
import { salesMng } from '../api/salesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditSaleDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onEdit, sale } = props;

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ isValid: true });

  React.useEffect(() => {
    setFormData({ values: { ...sale } });
  }, [sale]);

  const _onClose = () => {
    onClose();
  };

  const editSale = async () => {
    console.log('on edit');
    console.log(sale);

    setLoading(true);

    const _updatedSale = Object.assign(sale, formData.values);
    delete _updatedSale.isValid;

    try {
      await salesMng.updateSale(_updatedSale);
      onEdit(_updatedSale);
      onClose();
      toast.success('Sale updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  if (!sale || !open) {
    return null;
  }
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Edit Sale
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <SaleForm
          validateOnInit={true}
          onUpdate={(_formData) => {
            setFormData({ ...Object.assign(formData, _formData) });
          }}
          formData={formData}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save Sale</span>}
            onClick={editSale}
            onEnter={editSale}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditSaleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editSale: PropTypes.func,
  sale: PropTypes.object,
  onEdit: PropTypes.func,
};

EditSaleDialog.defaultProps = {
  open: false,
  onClose: () => {},
  sale: {},
};

export default EditSaleDialog;
