import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { salesMng } from '../api/salesMng';
import SaleForm from './SaleForm';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddSaleDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onAdd = () => {} } = props;

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState();

  const createSale = async () => {
    setLoading(true);
    const sale = { ...formData.values };
    delete sale.isValid;
    try {
      await salesMng.addSale(sale);
      onAdd(sale);
      _onClose();
      toast.success('Sale added successfully', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setFormData({});
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Add Sale
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <SaleForm
          onUpdate={(data) => {
            setFormData({ ...data });
          }}
          formData={formData}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || !formData || !formData.isValid}
            label={<span>Add Sale</span>}
            onClick={createSale}
            onEnter={createSale}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddSaleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
};

AddSaleDialog.defaultProps = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: () => {},
};

export default AddSaleDialog;
