import React from 'react';
import PropTypes from 'prop-types';

import { NoData, InfiniteScroll, EndlessScroll } from '@meronex/components';
import { App } from '@meronex/app';

import StoreItem from '../storeItems/StoreItem';
import ItemInDialog from '../storeItems/actions/ItemInDialog';
import ItemOutDialog from '../storeItems/actions/ItemOutDialog';
import ItemTransferDialog from './actions/ItemTransferDialog';
import AuditItemDialog from '../storeItems/actions/AuditItemDialog';
import ActionsDialog from '../storeItems/actions/ActionsDialog';

import { useWindowSize } from '@react-hook/window-size';

import { storesMng } from '../../api/storesMng';

export default function StoreItemsList(props) {
  const {
    store,
    storeDrawerOpen,
    onAddStoreItem,
    onRemoveStoreItem,
    onRestoreStoreItem,
    onArchiveStoreItem,
    onModifyStoreItem,
    onReverseTransaction,
    onItemTransfer,
    onItemIn,
    onItemOut,
    onItemClick,
    viewOnly,
    permissions,
    filters,
  } = props;

  const [openItemInDialog, setOpenItemInDialog] = React.useState(false);
  const [openItemOutDialog, setOpenOutDialog] = React.useState(false);
  const [openTransferDialog, setOpenTransferDialog] = React.useState(false);
  const [openAuditDialog, setOpenAuditDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState();
  const [selectedItemProducts, setSelectedItemProducts] = React.useState();
  const infiniteScrollRef = React.useRef(null);
  const [width, height] = useWindowSize();
  const [openActionsDialog, setOpenActionsDialog] = React.useState();

  const getStoreItemProducts = async () => {
    if (selectedItem && selectedItem._id) {
      const result = await storesMng.getStoreItemProducts(selectedItem._id);
      setSelectedItemProducts(result);
    }
  };

  React.useEffect(() => {
    App.eventsManager.on('event-active-view', StoreItemsList.name, (e) => {
      if (e.activeView === 'stores') {
        return refreshList();
      }
    });
  }, []);

  React.useEffect(() => {
    console.log(filters);
    console.log('fetch filters');
    window.clearTimeout(window.fetch);
    window.fetch = setTimeout(() => {
      refreshList();
    }, 450);
  }, [filters, store]);

  React.useEffect(() => {
    if (selectedItem) {
      getStoreItemProducts();
    }
  }, [selectedItem]);

  const refreshItems = () => {
    console.log('refresh items');
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.refreshItems();
    }
  };

  const refreshSelectedItem = (scrollToTop, clearItems) => {
    // note that scrollToTop and clearItems are kept for backward
    // compatability with infinite list
    const { current } = infiniteScrollRef;

    if (current && typeof current.refreshSelectedItem) {
      infiniteScrollRef.current.refreshSelectedItem(selectedItemIndex);
    } else refreshList(scrollToTop, clearItems);
  };

  const refreshList = (scrollToTop, clearItems) => {
    console.log('[StoreItemsList]: refresh list..');
    window.skip = null;
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.refreshList(scrollToTop, clearItems);
    }
  };

  const renderItem = (item, index) => {
    if (!item || !item.type) {
      return null;
    }
    return (
      <StoreItem
        key={item._id}
        item={item}
        permissions={permissions}
        itemProducts={selectedItemProducts}
        showItemDetails={false}
        onClick={() => {
          if (!viewOnly) {
            setSelectedItem(item);
            setSelectedItemIndex(index);
            setOpenActionsDialog(true);
            onItemClick(item);
          }
        }}
        onItemIn={() => {
          setOpenItemInDialog(true);
          setSelectedItem(item);
        }}
        onItemOut={() => {
          setOpenOutDialog(true);
          setSelectedItem(item);
        }}
        onItemTransfer={() => {
          setOpenTransferDialog(true);
          setSelectedItem(item);
        }}
        onItemAudit={() => {
          setOpenAuditDialog(true);
          setSelectedItem(item);
        }}
        onRemoveStoreItem={onRemoveStoreItem}
        onRestoreStoreItem={onRestoreStoreItem}
        showBatches={false}
        // last={index === storeItems.length - 1}
        onToggleActive={() => {
          refreshSelectedItem();
        }}
      />
    );
  };
  const getItemHeight = (item) => {
    let height = 120;
    if (item) {
      if (item.type && item.type.price) {
        height += 25;
      }
      if (item.location) {
        height += 25;
      }
    }

    return height;
  };

  const fetchStoreItem = async (itemId) => {
    console.log(`fetchStoreItem: itemId ${itemId}`);
    return await storesMng.getStoreItem(itemId);
  };

  const fetchStoreItems = async ({ limit, skip }) => {
    console.log(`fetchStoreItems: limit: ${limit}, skip:  ${skip}`);
    let _storeItems;
    // setFetching(true);

    // this is added to prevent the bug
    // when filtering and while having scrolled down
    // causing a flicker due to infinite loop
    if (skip === 0 || skip > window.skip) {
      window.skip = skip;
      _storeItems = await storesMng.getStoreItemsCursor({
        storeId: store._id,
        skip,
        limit,
        filters,
      });
    }
    // console.log(_storeItems);
    // console.log(_storeItems);
    // setStoreItems(_storeItems);
    return _storeItems;
  };

  const getHeight = () => {
    let isMobile = App.utils.isMobile();
    // increase the offset to see more/increase height
    let offset = isMobile ? 0.19 : 0.25;
    return height - height * offset;
  };

  return (
    <div>
      <div key={store._id}>
        <EndlessScroll
          ref={infiniteScrollRef}
          scrollRenderer={(items, startIndex, stopIndex) => {
            const item = items[stopIndex];
            if (item && item.type && item.type.name) {
              return (
                <div
                  style={{
                    width: '240px',
                    margin: 'auto',
                    backgroundColor: 'rgba(25, 25, 25, 0.74)',
                    color: 'white',
                    borderRadius: '10px',
                    padding: '2px',
                    fontSize: '15px',
                  }}>
                  {stopIndex + 1} - {item.type.name}
                </div>
              );
            }
            return <span />;
          }}
          height={getHeight()}
          remoteRowCount={9999999}
          selectedItemIndex={selectedItemIndex}
          listStyle={{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
          renderItem={(data, isScrolling, index) => {
            return renderItem(data, index);
          }}
          itemHeight={(item) => {
            return getItemHeight(item);
          }}
          lastItemHeight={(item) => {
            return getItemHeight(item) + 135;
          }}
          noRowRenderer={() => {
            return (
              <div>
                <NoData
                  onClick={() => {
                    onAddStoreItem();
                  }}
                  style={{
                    padding: '50px',
                  }}
                  message={'Add store item'}
                />
              </div>
            );
          }}
          getListItem={fetchStoreItem}
          getListItems={fetchStoreItems}
        />
      </div>
      {!viewOnly && selectedItem && (
        <React.Fragment>
          <ItemInDialog
            open={openItemInDialog}
            item={selectedItem}
            onClose={() => {
              setOpenItemInDialog(false);
            }}
            onItemIn={(cb) => {
              if (onItemIn) {
                onItemIn(cb);
              } else {
                cb();
              }
              refreshSelectedItem(true, true);
            }}
          />
          <ItemOutDialog
            open={openItemOutDialog}
            item={selectedItem}
            onItemOut={() => {
              console.log('on item out');
              if (onItemOut) {
                onItemOut();
              }
              refreshSelectedItem(true, true);
            }}
            onClose={() => {
              setOpenOutDialog(false);
            }}
          />
          <ItemTransferDialog
            open={openTransferDialog}
            item={selectedItem}
            onItemTransfer={() => {
              if (onItemTransfer) {
                onItemTransfer();
              }
              refreshSelectedItem(true, true);
            }}
            onClose={() => {
              setOpenTransferDialog(false);
            }}
            f
          />
          {openAuditDialog && (
            <AuditItemDialog
              open={openAuditDialog}
              item={selectedItem}
              itemProducts={selectedItemProducts}
              permissions={permissions}
              onArchiveStoreItem={() => {
                console.log('on archive');
                refreshList(true, true);
                if (onArchiveStoreItem) {
                  onArchiveStoreItem();
                }
              }}
              onReverseTransaction={() => {
                console.log('on reverse');
                refreshList(true, true);
                if (onReverseTransaction) {
                  onReverseTransaction();
                }
              }}
              onModifyStoreItem={() => {
                refreshList(true, true);
                if (onModifyStoreItem) {
                  onModifyStoreItem();
                }
                setOpenAuditDialog(false);
              }}
              onAdjustUnitRate={() => {
                refreshList(true, true);
                setOpenAuditDialog(false);
              }}
              onClose={() => {
                setOpenAuditDialog(false);
              }}
            />
          )}
          {openActionsDialog && selectedItem && (
            <ActionsDialog
              permissions={permissions}
              item={selectedItem}
              open={openActionsDialog}
              onClose={() => {
                setOpenActionsDialog(false);
              }}
              onItemIn={() => {
                setOpenItemInDialog(true);
                setOpenActionsDialog(false);
              }}
              onItemOut={() => {
                setOpenOutDialog(true);
                setOpenActionsDialog(false);
              }}
              onItemRestore={onRestoreStoreItem}
              onItemTransfer={() => {
                setOpenTransferDialog(true);
                setOpenActionsDialog(false);
              }}
              onItemManage={() => {
                setOpenAuditDialog(true);
                setOpenActionsDialog(false);
              }}
              onItemDelete={onRemoveStoreItem}
              onToggleActive={refreshSelectedItem}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

StoreItemsList.propTypes = {
  store: PropTypes.object,
  onItemClick: PropTypes.func,
  storeDrawerOpen: PropTypes.bool,
  onExpandItem: PropTypes.func,
  onAddStoreItem: PropTypes.func,
  onAddStoreProduct: PropTypes.func,
  onModifyStoreItem: PropTypes.func,
  onRestoreStoreItem: PropTypes.func,
  onArchiveStoreItem: PropTypes.func,
  onReverseTransaction: PropTypes.func,
  onItemIn: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemTransfer: PropTypes.func,
  viewOnly: PropTypes.bool,
  permissions: PropTypes.object,
  filters: PropTypes.object,
};
