import React from 'react';
import PropTypes from 'prop-types';
import { Icons } from '../common/Icons';
import View from '../views/components/View';
import SalesList from './components/SalesList';
import AddSalesDialog from './components/AddSaleDialog';
import EditSalesDialog from './components/EditSaleDialog';
import SalesDrawer from './components/SalesDrawer';
import { salesMng } from './api/salesMng';

function SalesView(props) {
  const { activeView } = props;

  const [sales, setSales] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [openAddSaleDialog, setOpenAddSaleDialog] = React.useState(false);
  const [openEditSaleDialog, setOpenEditSaleDialog] = React.useState(false);

  const fetchSales = async () => {
    console.log('fetching sales..');
    const sales = await salesMng.getSales();
    setSales(sales);
    console.log(sales);
    setSelectedItem();
  };
  React.useEffect(() => {
    fetchSales();
  }, []);

  const onItemClick = async (saleItem) => {
    setSelectedItem(saleItem);
  };
  const onDeleteConfirm = async (saleItem) => {
    await salesMng.deleteSale(saleItem);
    await fetchSales();
  };
  const onEditClick = async () => {
    setOpenEditSaleDialog(true);
  };
  return (
    <View
      viewId={'sales'}
      activeView={activeView}
      showMenu
      title={'Sales'}
      icon={<Icons.Sales size={25} />}>
      <div>
        <SalesList
          sales={sales}
          onClick={onItemClick}
          onEdit={onEditClick}
          onDelete={onDeleteConfirm}
        />
        <AddSalesDialog
          onAdd={fetchSales}
          open={openAddSaleDialog}
          onClose={() => {
            setOpenAddSaleDialog(false);
          }}
        />
        {selectedItem && (
          <EditSalesDialog
            onEdit={() => fetchSales()}
            open={openEditSaleDialog}
            onClose={() => {
              setOpenEditSaleDialog(false);
            }}
            sale={selectedItem}
          />
        )}
        <SalesDrawer onAddSaleItem={() => setOpenAddSaleDialog(true)} />
      </div>
    </View>
  );
}

SalesView.propTypes = {};

SalesView.defaultProps = {};
export default SalesView;
