import { App } from '@meronex/app';
import React from 'react';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Slide from '@material-ui/core/Slide';
import BiRefresh from '@meronex/icons/bi/BiRefresh';
import LoadingBtn from '../../meronex-app/components/buttons/LoadingBtn';

export const can = (action, user) => {
  const actionPath = action.split('.');
  const category = actionPath[0];
  const actionId = actionPath[1];
  if (!user) {
    user = App.getUser();
  }
  if (user) {
    const rootOrAdmin = (user.roles || []).some((i) =>
      ['admin', 'root', 'manager'].includes(i)
    );
    if (rootOrAdmin) {
      return true;
    }
  }

  if (
    user &&
    user.settings &&
    user.settings.sites &&
    user.settings.sites.role &&
    user.settings.sites.role[category] &&
    user.settings.sites.role[category][actionId]
  ) {
    return true;
  }
  return false;
};
export const p = {
  stores: {
    itemIn: 'stores.itemIn',
    itemOut: 'stores.itemOut',
    itemArchive: 'stores.itemArchive',
    itemRestore: 'stores.itemRestore',
    itemDelete: 'stores.itemDelete',
    transferItem: 'stores.transferItem',
    auditItem: 'stores.auditItem',
    modifyItemAmount: 'stores.modifyItemAmount',
    reverseTransaction: 'stores.reverseTransaction',
    auditStore: 'stores.auditStore',
    CRUDItemTypes: 'stores.CRUDItemTypes',
    viewStoresPrices: 'stores.viewStoresPrices',
  },
  factories: {
    CRUDFactories: 'factories.CRUDFactories',
    CRUDWorkOrders: 'factories.CRUDWorkOrders',
    modifyOthersWorkOrder: 'factories.modifyOthersWorkOrder',
    deleteOthersWorkOrder: 'factories.deleteOthersWorkOrder',
    CRUDProducts: 'factories.CRUDProducts',
    updateWorkOrderStatus: 'factories.updateWorkOrderStatus',
    reverseWorkOrder: 'factories.reverseWorkOrder',
    viewFactoriesPrices: 'factories.viewFactoriesPrices',
  },
  globals: {
    accessFactories: 'globals.accessFactories',
    accessStores: 'globals.accessStores',
    hideFactoriesModule: 'globals.hideFactoriesModule', // legacy, replaced with accessFactories
    accessTasks: 'globals.accessTasks',
    accessEquipments: 'globals.accessEquipments',
    accessSales: 'globals.accessSales',
    accessFeed: 'globals.accessFeed',
    viewPrices: 'globals.viewPrices',
    viewConfigurations: 'globals.viewConfigurations',
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PermissionsChecker = (props) => {
  // 1. subscribe to changes the user role based on the role id, this happens as soon as the user
  // is logged on
  // 2. once the admin make changes to the user doc, then show a popup a dialog
  // 3. like the version checker once we detect a change then force refresh to the app
  const { server } = App;
  // Need to compare the updated at, which is stored under role.updatedAt.date
  // with the current role.updatedAt.date and if they are different then we need to force refresh

  const [showDialog, setShowDialog] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);

  const handleUserChange = (nextUser) => {
    console.log('[PermissionsChecker]: handleUserChange called');
    if (!window.prevUser) {
      console.log('[PermissionsChecker]: no prev user ignore');
    } else {
      console.log('[PermissionsChecker]: compare user roles');
      const nextUpdateDate = new Date(
        nextUser?.settings?.sites?.role?.updatedAt?.date
      );
      const prevUpdateDate = new Date(
        window.prevUser?.settings?.sites?.role?.updatedAt?.date
      );
      const nextUpdateTime = nextUpdateDate.getTime();
      const prevUpdateTime = prevUpdateDate.getTime();

      console.log(prevUpdateDate.getTime());
      if (
        nextUpdateTime &&
        prevUpdateTime &&
        nextUpdateTime !== prevUpdateTime
      ) {
        setShowDialog(true);
      }
    }
    if (nextUser) {
      window.prevUser = App.utils.clone(nextUser);
    }
  };

  async function setupSubscription() {
    const roleDataSub = await server.subscribe('roleData');

    if (!window.roleSubInit && roleDataSub.ready()) {
      const user = App.getUser();
      let users = server
        .collection('users')
        .filter((user) => user._id == user._id)
        .reactive()
        .one();
      users.onChange((user) => {
        handleUserChange(user);
      });
      window.roleSubInit = true;
    }
  }

  React.useEffect(() => {
    App.eventsManager.on(
      'event-user-loaded',
      'PermissionsChecker',
      setupSubscription
    );
    return () => {
      console.log('[PermissionsChecker]:cleanup');
      window.roleSubInit = null;
      window.prevUser = null;
      App.eventsManager.removeListener(
        'event-user-loaded',
        'PermissionsChecker',
        setupSubscription
      );
    };
  }, []);
  return (
    <Dialog
      className={'permissions-checker-dialog'}
      open={showDialog}
      TransitionComponent={Transition}
      keepMounted
      disableBackdropClick={true}
      showCloseBtn={false}>
      <DialogTitle>Permissions Changed</DialogTitle>
      <DialogContent>
        <p>
          The administrator changed the permissions for your role, please
          refresh the app to see the changes
        </p>
        <LoadingBtn
          style={{
            width: '100%',
            borderRadius: '20px',
            marginTop: '20px',
            marginBottom: '20px',
          }}
          loading={refreshing}
          onClick={() => {
            setRefreshing(true);
            setTimeout(() => {
              App.forceReload();
            }, 500);
          }}>
          <span style={{ marginRight: '5px' }}>Refresh</span>
          <BiRefresh />
        </LoadingBtn>
      </DialogContent>
    </Dialog>
  );
};
