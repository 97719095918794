import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import IHome from '@meronex/icons/ai/AiOutlineHome';
import IFactory from '@meronex/icons/mdc/MdcFactory';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import IRefresh from '@meronex/icons/ios/MdRefresh';

import { toast } from 'react-toastify';
import { App } from '@meronex/app';

import { p, can } from '../../common/roles';
import { Icons } from '../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const activeStyle = {
  color: 'rgb(85, 108, 214)',
};

function HeaderMenuDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onRefresh, activeItemId } = props;

  const [refreshing, setRefreshing] = React.useState(false);

  const ViewMenuItem = ({
    viewId,
    size = 18,
    fontSize,
    Icon,
    iconSize,
    iconClassName,
    label,
    onClick,
  }) => {
    return (
      <MenuItem
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            setActiveView(viewId);
          }
        }}
        style={activeItemId === viewId ? activeStyle : { height: '50px' }}>
        <Icon size={iconSize || size} className={iconClassName} />
        <span
          style={{
            fontSize: `${fontSize || size}px`,
          }}>
          &nbsp;&nbsp;{label}
        </span>
      </MenuItem>
    );
  };

  const _onRefresh = () => {
    setRefreshing(!refreshing);
    onRefresh(() => {
      setRefreshing(false);
      toast.success('View successfully refreshed!', {
        autoClose: 800,
      });
      onClose();
    });
  };

  const setActiveView = (view) => {
    App.eventsManager.emit('event-active-view', HeaderMenuDialog.name, {
      activeView: view,
    });
    onClose();
  };

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <span
          style={{
            position: 'relative',
          }}>
          <span
            style={{
              position: 'relative',
              top: '6px',
              right: '10px',
            }}>
            <Icons.Menu />
          </span>
          Menu
        </span>
        <div>
          <CloseBtn
            style={{
              right: '-18px',
              top: '5px',
            }}
            onClick={() => {
              onClose();
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
        }}>
        <div>
          <ViewMenuItem viewId={'home'} label={'Home'} Icon={IHome} />
          <Divider />
          {can(p.globals.accessStores) && (
            <>
              <ViewMenuItem viewId={'stores'} Icon={IStores} label={'Stores'} />
              <Divider />
            </>
          )}
          {can(p.globals.accessFactories) && (
            <>
              <ViewMenuItem
                viewId={'factories'}
                label={'Production'}
                Icon={IFactory}
              />
              <Divider />
            </>
          )}
          {can(p.globals.accessEquipments) && (
            <>
              <ViewMenuItem
                viewId={'equipments'}
                label={
                  <span
                    style={{
                      position: 'relative',
                      marginLeft: '-2px',
                    }}>
                    Equipments
                  </span>
                }
                Icon={Icons.Equipments}
                iconSize={22}
              />
              <Divider />
            </>
          )}
          {can(p.globals.accessSales) && (
            <>
              <ViewMenuItem
                viewId={'sales'}
                label={'Sales'}
                Icon={Icons.Sales}
              />
              <Divider />
            </>
          )}
          {onRefresh && (
            <div>
              <ViewMenuItem
                viewId={'refresh'}
                label={'Refresh'}
                Icon={IRefresh}
                iconClassName={refreshing ? 'rotate' : ''}
                onClick={_onRefresh}
              />
              <Divider />
            </div>
          )}
          {can(p.globals.viewConfigurations) && (
            <ViewMenuItem
              viewId={'configurations'}
              label={'Configurations'}
              Icon={Icons.Settings}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

HeaderMenuDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default HeaderMenuDialog;
