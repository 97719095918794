import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import StoreItemDetails from '../StoreItemDetails';
import MenuList from '@material-ui/core/MenuList';
import IDelete from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';

import IOut from '@meronex/icons/bi/BiExport';
import IIn from '@meronex/icons/bi/BiImport';
import IManage from '@meronex/icons/ri/RiListSettingsLine';
import IRestore from '@meronex/icons/mdc/MdcFileRestoreOutline';
import { storesMng } from '../../../api/storesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ActionDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    onClose,
    item,
    permissions,
    itemProducts,
    onItemIn,
    onItemOut,
    onItemTransfer,
    onItemManage,
    onItemDelete,
    onItemRestore,
    onToggleActive
  } = props;

  const [clickCount, setClickCount] = React.useState(0);
  const [itemState, setItemState] = React.useState(item);

  React.useEffect(() => {
    setItemState(item);
  }, [item]);

  if (!itemState) {
    return null;
  }

  const _onClose = () => {
    onClose();
  };
  
  const _onItemDelete = async () => {
    const result = await storesMng.deleteStoreItem(itemState._id);
    toast.success('Store item deleted', { autoClose: 800 });
    onItemDelete();
    _onClose();
    onClose();
  };
  const _onItemRestore = async () => {
    console.log('on restore');
    const result = await storesMng.restoreStoreItem(itemState._id);
    toast.success('Store item restored', { autoClose: 800 });
    _onClose();
    onItemRestore();
    onClose();
  };

  const _onToggleActive = async (itemId, isActive) => {
    try {
      await storesMng.updateStoreItem(itemId, {
        _id: itemState._id,
        name: itemState.type.name,
        itemCount: itemState.quantity,
        communityId: itemState.communityId,
        createdAt: itemState.createdAt,
        isActive,
      });
      // Update local state immediately
      setItemState(prev => ({ ...prev, isActive }));
      toast.success(
        `Item ${isActive ? 'activated' : 'deactivated'} successfully`,
        { autoClose: 800 }
      );
      if(onToggleActive){
        onToggleActive();
      }
    } catch (error) {
      console.error('Error toggling item active state:', error);
      toast.error('Failed to update item status');
    }
  };

  const renderButton = ({ onClick, label, icon, color, disabled }) => {
    const isInactive = itemState.isActive === false;
    const buttonDisabled = disabled || isInactive;

    return (
      <MenuItem
        onClick={() => {
          if (!buttonDisabled) {
            onClick();
          }
        }}
        style={{
          width: '100%',
          color: isInactive ? 'rgba(0, 0, 0, 0.38)' : color,
          minHeight: '75px',
          borderBottom: '1px solid #e8e7e8',
          opacity: isInactive ? 0.6 : 1,
          cursor: buttonDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: buttonDisabled ? 'none' : 'auto',
        }}>
        <div
          style={{
            width: '100%',
            textAlign: 'left',
          }}>
          {icon}

          <span
            style={{
              marginLeft: '15px',
              fontSize: '18px',
            }}>
            {label}
          </span>
        </div>
      </MenuItem>
    );
  };

  console.log(clickCount);
  return (
    <Dialog
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      onBackdropClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      open={open}
      className={'fullScreenMobile noselect'}>
      <DialogTitle>
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <div
          onClick={() => {
            setClickCount(clickCount + 1);
          }}
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}>
          <StoreItemDetails
            item={itemState}
            permissions={permissions}
            prodcuts={itemProducts}
            showTransaction={true}
            showStore={true}
            showUpdated
            showId={clickCount > 2}
            showDetails={true}
            showBatches={true}
            onToggleActive={_onToggleActive}
          />
        </div>
        <MenuList
          style={{
            padding: '0px',
            marginTop: '10px',
            marginBottom: '35px',
            border: '1px solid #e8e7e8',
            borderRadius: '10px',
          }}>
          {permissions.in &&
            !itemState.store.productsStore &&
            renderButton({
              onClick: onItemIn,
              label: 'In',
              icon: <IIn size={25} />,
              color: 'green',
              disabled: !permissions.in || itemState.isActive === false,
            })}
          {permissions.out &&
            renderButton({
              onClick: onItemOut,
              label: 'Out',
              icon: <IOut size={25} />,
              color: 'red',
              disabled: !permissions.out || itemState.isActive === false,
            })}
          {permissions.transfer &&
            renderButton({
              onClick: onItemTransfer,
              label: 'Transfer',
              icon: <IOut size={25} />,
              color: '#5970d7',
              disabled: !permissions.transfer || itemState.isActive === false,
            })}
          {permissions.auditItem &&
            renderButton({
              onClick: onItemManage,
              label: 'Audit',
              icon: <IManage size={25} />,
              color: 'black',
              disabled: !permissions.auditItem || itemState.isActive === false,
            })}
          {itemState.archived && (
            <>
              {permissions.restore &&
                renderButton({
                  onClick: () => {
                    _onItemRestore();
                  },
                  label: 'Restore',
                  icon: <IRestore size={25} />,
                  color: 'green',
                  disabled: !permissions.restore,
                })}
              {permissions.delete &&
                renderButton({
                  onClick: () => {
                    _onItemDelete();
                  },
                  label: 'Delete',
                  icon: <IDelete size={25} />,
                  color: 'red',
                  disabled: !permissions.delete,
                })}
            </>
          )}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
}

ActionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.object,
  permissions: PropTypes.object,
  itemProducts: PropTypes.array,
  onItemIn: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemTransfer: PropTypes.func,
  onItemManage: PropTypes.func,
  onItemDelete: PropTypes.func,
  onItemRestore: PropTypes.func,
};

export default ActionDialog;
