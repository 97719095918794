import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import IFactory from '@meronex/icons/mdc/MdcFactory';
import Confetti from 'react-confetti';
import Fade from '@material-ui/core/Fade';

import View from '../views/components/View';

import FactoriesHomePage from './FactorieHomePage';
import { factoriesMng } from './api/FactoriesMng';
import FactoriesDialog from './components/factories/FactoriesDialog';
import { Loading } from '@meronex/components';
import loadingGif from './factories.gif';

function FactoriesView(props) {
  const { activeView, onWorkOrderAdded } = props;

  const viewId = 'factories';

  const [factoriesLoading, setFactoriesLoading] = React.useState(true);
  const [factorySelectLoading, setFactoriesSelectLoading] = React.useState(
    false
  );

  const [factories, setFactories] = React.useState();
  const [products, setProducts] = React.useState();

  const [filterValue, setFilterValue] = React.useState('');
  const [selectedFactory, setSelectedFactory] = React.useState();
  const [openFactoriesDialog, setOpenFactoriesDialog] = React.useState(false);

  const [celebrateWOAdd, setCelebrateWOAdd] = React.useState(false);

  const updateUserSelectFactory = async () => {
    const selectedFactory = await factoriesMng.getUserSelectedFactory(
      factories
    );
    setSelectedFactory(selectedFactory);
  };

  React.useEffect(() => {
    console.log('factory view load');
    App.eventsManager.on('event-refresh-views', viewId, () => {
      refresh();
    });
  }, []);

  React.useEffect(() => {
    if (Array.isArray(factories)) {
      updateUserSelectFactory();
    }
  }, [factories]);

  React.useEffect(() => {
    if (activeView !== viewId) {
      setFilterValue(null);
    } else {
      fetchFactories();
    }
  }, [activeView]);

  const refresh = async () => {
    console.log('refresh factories');
    await fetchFactories();
  };

  const fetchFactories = async () => {
    console.log('fetch factories');
    setFactoriesLoading(true);
    const factories = await factoriesMng.getFactories();
    setFactories(factories);
    // update the selected factory or set to null if
    // it was deleted
    if (selectedFactory && Array.isArray(factories)) {
      const fetchedSelectedFactory = factories.find(
        (f) => f._id === selectedFactory._id
      );
      setSelectedFactory(fetchedSelectedFactory);
    }
    setFactoriesLoading(false);
  };

  const onFactorySelect = async (factory, cb) => {
    console.log('on factory select');
    console.dir(factory);
    setFactoriesSelectLoading(true);
    setSelectedFactory(factory);
    factoriesMng.setUserSelectedFactory(factory ? factory._id : '');
    if (typeof cb === 'function') {
      cb();
    }
    setFactoriesSelectLoading(false);
  };

  const _onWorkOrderAdded = () => {
    setFilterValue('');
    onWorkOrderAdded();
    setCelebrateWOAdd(true);
    window.setTimeout(() => {
      setCelebrateWOAdd(false);
    }, 1200);
  };

  const getTitle = () => {
    let title;
    // keep it null if not selected
    // thus hiding the header
    if (selectedFactory) {
      title = <span>{`${selectedFactory.name}`}</span>;
    } else {
      title = <span>Select a Factory</span>;
    }
    return title;
  };

  return (
    <View
      viewId={'factories'}
      showMenu
      icon={<IFactory size={22} />}
      onTitleDropdownClick={() => {
        setOpenFactoriesDialog(true);
      }}
      onFactorySelect={onFactorySelect}
      title={getTitle()}
      activeView={activeView}
      onRefresh={async (cb) => {
        await refresh();
        cb();
      }}>
      {/*onRefresh={async (cb) => {*/}
      {/*  console.log('on refresh');*/}
      {/*  await fetchFactories();*/}
      {/*  await updateUserSelectFactory();*/}
      {/*  if (cb) {*/}
      {/*    cb();*/}
      {/*  }*/}
      {/*}}>*/}
      <div>
        <div>
          {!selectedFactory && (
            <div>
              <Loading
                type={'img'}
                text={
                  'Please select a production site from the top menu or create new one'
                }
                isShowRetry={false}
                url={loadingGif}
              />
            </div>
          )}
          {selectedFactory && (
            <FactoriesHomePage
              onRefresh={refresh}
              loading={factoriesLoading}
              factories={factories}
              onFactorySelect={onFactorySelect}
              selectedFactory={selectedFactory}
              onWorkOrderAdded={_onWorkOrderAdded}
              fetchFactories={fetchFactories}
            />
          )}
          <FactoriesDialog
            factorySelectLoading={factorySelectLoading}
            refreshFactories={() => {
              fetchFactories();
            }}
            selectedFactory={selectedFactory}
            factories={factories}
            onDelete={() => {
              setFactoriesSelectLoading(false);
              fetchFactories();
            }}
            onEdit={() => {
              setFactoriesSelectLoading(false);
              fetchFactories();
            }}
            onSelectClose={() => {
              setFactoriesSelectLoading(false);
            }}
            onFactorySelect={onFactorySelect}
            open={openFactoriesDialog}
            onClose={() => {
              setOpenFactoriesDialog(false);
            }}
          />
          <Fade in={celebrateWOAdd}>
            <Confetti
              numberOfPieces={50}
              gravity={0.1}
              tweenDuration={1200}
              confettiSource={{
                x: 0,
                y: 0,
                w: window.outerWidth,
                h: 0,
              }}
            />
          </Fade>
        </div>
      </div>
    </View>
  );
}
FactoriesView.propTypes = {
  activeView: PropTypes.string,
  onWorkOrderAdded: PropTypes.func,
};

FactoriesView.defaultProps = {
  onWorkOrderAdded: () => {},
};
export default FactoriesView;
