import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import MdMenu from '@meronex/icons/md/MdMenu';
import { ListTextItem } from '@meronex/components';

import MdAddCircleOutline from '@meronex/icons/ios/MdAddCircleOutline';
import { useHistory } from 'react-router-dom';

function SalesDrawer(props) {
  const history = useHistory();

  const { onAddSaleItem, onClose } = props;

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const _onClose = () => {
    setOpenDrawer(false);
    onClose();
  };

  return (
    <div>
      <Fab
        onClick={() => {
          setOpenDrawer(true);
        }}
        aria-label="add"
        className="fab-menu">
        <MdMenu size={25} />
      </Fab>
      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            _onClose();
          }}>
          <List>
            <ListTextItem
              key="addSaleItem"
              text={'Add sale item'}
              onClick={() => {
                onAddSaleItem();
                _onClose();
              }}
              icon={<MdAddCircleOutline size={25} />}
              iconSize={25}
            />
            <Divider />
            <ListItem
              button
              key="close"
              onClick={() => {
                _onClose();
              }}>
              <div className={'list-item-text'}>Close</div>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </div>
  );
}
SalesDrawer.propTypes = {
  onClose: PropTypes.func,
};

SalesDrawer.defaultProps = {
  onClose: () => {},
};
export default SalesDrawer;
