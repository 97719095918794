import { App } from '@meronex/app';

const { server } = App;

let sales = [
  { _id: '2', name: 'sale1', checked: false },
  { _id: '3', name: 'sale2', checked: false },
  { _id: '4', name: 'sale3', checked: true },
];

export const salesMng = {
  addSale: async (sale) => {
    // const result = await server.call('addSale', {sale});
    sale._id = App.utils.randomId();
    const result = sales.push(sale);
    return result;
  },
  updateSale: async (sale) => {
    // const result = await server.call('updateSale', {sale});
    let result = sales;
    console.log(sale);
    const index = sales.findIndex((i) => {
      console.log(i);
      return i._id === sale._id;
    });
    if (index > 0) {
      sales[index] = sale;
    }
    return result;
  },
  getSales: async () => {
    // const result = await server.call('getSales', {});
    const result = sales;
    return result;
  },
  deleteSale: async (sale) => {
    console.log(`delete sale, id: ${sale._id}`);
    // const result = await server.call('deleteSale', { _id: sale._id });
    const result = sales.filter((i) => i._id !== sale._id);
    sales = result;
    return result;
  },
};
