import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import GiBackwardTime from '@meronex/icons/cg/CgTimer';

const DefaultScrollRenderer = (props) => {
  const { startIndex, items } = props;

  if (!Array.isArray(items) || items.length === 0) {
    return <span />;
  }
  const startItem = items[startIndex];

  if (!startItem || !startItem.createdAt) {
    return <div />;
  }
  const getContent = () => {
    return (
      <div
        style={{
          width: '240px',
          margin: 'auto',
          backgroundColor: 'rgba(25, 25, 25, 0.74)',
          color: 'white',
          borderRadius: '10px',
          padding: '2px',
          fontSize: '15px',
          zIndex: 99999,
        }}>
        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            left: '-8px',
            top: '2px',
          }}>
          <GiBackwardTime size={22} color={'white'} />
        </div>
        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            top: '-5px',
          }}>
          {App.utils.time.timeFullFormat(startItem.createdAt)}
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '25px',
        width: '100%',
        left: '50%',
        textAlign: 'center',
        transform: 'translateX(-50%)',
        zIndex: 99999,
      }}>
      {getContent()}
    </div>
  );
};

DefaultScrollRenderer.defaultProps = {};

DefaultScrollRenderer.propTypes = {
  scrollRenderer: PropTypes.func,
  startIndex: PropTypes.number,
  stopIndex: PropTypes.number,
  items: PropTypes.array,
};

export default DefaultScrollRenderer;
